<template>
    <div class="min-h-login-screen flex items-center justify-center bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full login" @keyup="processKeyPress($event)">
        <div>
            <div class="flex justify-center">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
                <img class="h-12" src="../assets/img/logos/sjef_logo-white.png" alt="Sjef logo" />
            </div>
            <h2 class="mt-6 text-center text-2xl leading-9 font-extrabold text-white">
                {{$t('translations.views.login.title')}}
            </h2>
            <div class="h-5">
                <p class="text-red-500" v-if="invalid">{{$t('translations.views.login.invalid-credentials')}}</p>
            </div>
        </div>
        <form class="mt-8" action="#" @submit.prevent="submit" autocomplete="off">
            <div class="rounded-sm default-input shadow-sm">

                <div class="" :class="{'active' : field === 'client_code'}">
                    <input @click="setField('client_code')" :placeholder="$t('translations.views.login.company-code')" readonly v-model="form.client_code" autofocus name="client_code" type="text" required />
                    <div class="clear-field" @click="clear('client_code')" v-if="form.client_code.length > 0">
                        +
                    </div>
                </div>
                <div class="mt-2" :class="{'active' : field === 'terminal_code'}">
                    <input @click="setField('terminal_code')" :placeholder="$t('translations.views.login.terminal-code')" readonly v-model="form.terminal_code" name="terminal_code" type="text" required class="@error('terminal_code') border-red-500 @enderror" />
                    <div class="clear-field" @click="clear('terminal_code')" v-if="form.terminal_code.length > 0">
                        +
                    </div>
                </div>
                <div class="mt-2 mb-3" :class="{'active' : field === 'terminal_pincode'}">
                    <input @click="setField('terminal_pincode')" :placeholder="$t('translations.views.login.pincode')" readonly v-model="form.terminal_pincode" name="terminal_pincode" type="password" required class="@error('terminal_pincode') border-red-500 @enderror" />
                    <div class="clear-field" @click="clear('terminal_pincode')" v-if="form.terminal_pincode.length > 0">
                        +
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-3 mt-2 gap-2">
                <span class="key inline-flex rounded-sm  shadow-sm">
                    <a @click="setInput('1')" class="button button-blur button-wide">
                        1
                    </a>
                </span>
                <span class="key inline-flex rounded-sm  shadow-sm">
                    <a @click="setInput('2')" class="button button-blur button-wide">
                        2
                    </a>
                </span>
                <span class="key inline-flex rounded-sm  shadow-sm">
                    <a @click="setInput('3')" class="button button-blur button-wide">
                        3
                    </a>
                </span>
            </div>

            <div class="grid grid-cols-3 mt-2 gap-2">
                <span class="key inline-flex rounded-sm  shadow-sm">
                    <a @click="setInput('4')" class="button button-blur button-wide">
                        4
                    </a>
                </span>
                <span class="key inline-flex rounded-sm  shadow-sm">
                    <a @click="setInput('5')" class="button button-blur button-wide">
                        5
                    </a>
                </span>
                <span class="key inline-flex rounded-sm  shadow-sm">
                    <a @click="setInput('6')" class="button button-blur button-wide">
                        6
                    </a>
                </span>
            </div>

            <div class="grid grid-cols-3 mt-2 gap-2">
                <span class="key col-span-1 inline-flex rounded-sm  shadow-sm">
                    <a @click="setInput('7')" class="button button-blur button-wide">
                        7
                    </a>
                </span>
                <span class="key col-span-1 inline-flex rounded-sm  shadow-sm">
                    <a @click="setInput('8')" class="button button-blur button-wide">
                        8
                    </a>
                </span>
                <span class="key col-span-1 inline-flex rounded-sm  shadow-sm">
                    <a @click="setInput('9')" class="button button-blur button-wide">
                        9
                    </a>
                </span>
            </div>

            <div class="grid grid-cols-3 mt-2 gap-2">
                <span class="key inline-flex rounded-sm  shadow-sm">
                </span>
                <span class="key inline-flex rounded-sm  shadow-sm">
                    <a @click="setInput('0')" class="button button-blur button-wide">
                        0
                    </a>
                </span>
                <span class="key inline-flex rounded-sm  shadow-sm">
                    <a @click="clear()" class="button button-blur button-wide">
                        {{$t('translations.views.login.clear')}}
                    </a>
                </span>
            </div>
            <div class="mt-6">
                <button type="submit" class="button button-filled button-large button-wide">
                    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                        <svg class="h-5 w-5 text-orange-200 group-hover:text-orange-100 transition ease-in-out duration-150" fill="currentColor" viewBox="0 0 20 20">
                            <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                        </svg>
                    </span>
                    {{$t('translations.views.login.button-login')}}
                </button>
            </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'Login',
    data () {
        return {
            field: 'client_code',
            invalid: false,
            form: {
                client_code: '',
                terminal_code: '',
                terminal_pincode: '',
            }
        }
    },

    methods: {
        ...mapActions({
            login: 'auth/login'
        }),

        setInput(value) {
            this.form[this.field] += value
        },

        processKeyPress(button) {
            if (button['key'] === 'Tab') {
                if (this.field === 'client_code') {
                    this.setField('terminal_code');
                } else if (this.field === 'terminal_code') {
                    this.setField('terminal_pincode');
                } else {
                    this.setField('client_code');
                }
            }

            if (button['key'] === 'Backspace') {
                let field_value = this.form[this.field];
                this.form[this.field] = field_value.slice(0, -1);
            }

          if (!isNaN(button['key'])) {
              this.form[this.field] += button['key']
          }
        },

        setField(field) {
            this.invalid = false;
            this.field = field;
        },
        clear(field = false) {
            if (field === false) {
                this.form.client_code = ''
                this.form.terminal_code = ''
                this.form.terminal_pincode = ''
                this.field = 'client_code';

            } else {
                this.form[field] = '';
                this.field = field;
            }
        },

        async submit () {

            // extra check to erase the masterdata
            localStorage.removeItem('masterdata.terminal');
            localStorage.removeItem('masterdata.settings');
            localStorage.removeItem('masterdata.items');
            localStorage.removeItem('masterdata.discounts');
            localStorage.removeItem('masterdata.paymenttypes');

            await this.login(this.form).then(response => {
                // get the terminal
                this.$router.replace({ name: response.terminal_type })
            }).catch(() => {
                this.invalid = true;
            })
        }
    }
}
</script>
